export const GET_LIST = 'GET_LIST';
export const GET_DATA = 'GET_DATA';

export const SAVE_CONFIG = 'SAVE_CONFIG';
export const GET_CONFIG = 'GET_CONFIG';

export const GET_CHAT_MESSAGES = 'GET_CHAT_MESSAGES';

export const GET_LOGIN_STATUS = 'GET_LOGIN_STATUS';

// MESSAGE
export const CREATE_MESSAGE = 'CREATE_MESSAGE';
export const GET_ERRORS = 'GET_ERRORS';

// USER AUTH
export const USER_LOADING = 'USER_LOADING';
export const USER_LOADED = 'USER_LOADED';
export const AUTH_ERROR = 'AUTH_ERROR';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const PAGE_ERROR = 'PAGE_ERROR';

export const SAVE_EMAIL_ADDRESS = 'SAVE_EMAIL_ADDRESS';
export const SAVE_EMAIL_ADDRESS_ERROR = 'SAVE_EMAIL_ADDRESS_ERROR';