import React, {Component} from "react";
import {
  Card,
  CardHeader,
  CardTitle,
  ListGroup,
  ListGroupItem,
} from "reactstrap";
import { languageText } from "../config";
import {connect} from "react-redux";

class Profile extends Component {
  render() {
      const { languageCode } = this.props.auth.user;
      const { linkActive } = this.props;
      return (<Card>
        <CardHeader>
          <CardTitle tag="h5" className="mb-0">
            {languageText[languageCode].components["usersProfile"].links.title}
          </CardTitle>
        </CardHeader>
        <ListGroup flush>
          <ListGroupItem tag="a" href="/profile" action active={linkActive === "/profile"}>
            {languageText[languageCode].components["usersProfile"].links.link1}
          </ListGroupItem>
          <ListGroupItem tag="a" href="/profile-password" action active={linkActive === "/profile-password"}>
            {languageText[languageCode].components["usersProfile"].links.link3}
          </ListGroupItem>
          <ListGroupItem tag="a" href="/profile-notifications" action active={linkActive === "/profile-notifications"}>
            {languageText[languageCode].components["usersProfile"].links.link4}
          </ListGroupItem>
        </ListGroup>
      </Card>);
    }

}

const mapStateToProps = state => ({
  auth: state.auth.user
});

export default connect(mapStateToProps, {} )(Profile);