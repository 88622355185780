import {
  Settings as SettingsIcon,
  Globe as OrganizationIcon,
  Sliders as SlidersIcon,
  Users as UsersIcon,
  ShoppingCart as OrdersIcon
} from "react-feather";

// Auth
import SignIn from "../pages/auth/SignIn";
import SignUp from "../pages/auth/SignUp";
import SignOut from "../pages/auth/SignOut";
import ResetPassword from "../pages/auth/ResetPassword";
import Page404 from "../pages/auth/Page404";
import Page500 from "../pages/auth/Page500";

// Misc
import Blank from "../pages/misc/Blank";

// Notifications
import Notifications from "../chcomponents/ProfileNotifications";

// Pages
import TeamMembers from "../chpages/Users";
import EmailTemplates from "../chpages/EmailTemplates";
import Orders from "../chpages/Orders";

// default pages
import Profile from "../chcomponents/Profile";

// Documentation
import ProfilePassword from "../chcomponents/ProfilePassword";

// Routes
const landingRoutes = {
  path: "/confirm-invitation",
  name: "Confirm Invitation",
  component: SignUp,
  isPrivate: false,
  children: null
};

const dashboardRoutes = {
  path: "/",
  name: "Dashboard",
  langName: "dashboard",
  component: Blank,
  icon: SlidersIcon,
  containsHome: true,
  isPrivate: true,
  children: null
};

// SETTINGS LINK
const ordersRoutes = {
  path: "/orders",
  name: "Orders",
  langName: "orders",
  icon: OrdersIcon,
  children: [
    {
      isPrivate: true,
      path: "/orders/list",
      langName: "viewOrders",
      component: Orders
    },
    {
      isPrivate: true,
      path: "/orders/list",
      langName: "todayShipOrders",
      component: Orders
    },
    {
      isPrivate: true,
      path: "/orders/list",
      langName: "salesReports",
      component: Orders
    },
    {
      isPrivate: true,
      path: "/orders/list",
      langName: "taxReport",
      component: Orders
    }
  ]
};

// SETTINGS LINK
const settingsRoutes = {
  path: "/settings",
  name: "Settings",
  langName: "settings",
  icon: SettingsIcon,
  children: [
    {
      isPrivate: true,
      path: "/organizations/team-members",
      name: "Users",
      langName: "teamMembers",
      component: TeamMembers
    },
    {
      isPrivate: true,
      path: "/settings/email-templates",
      name: "Email Templates",
      langName: "emailTemplates",
      component: EmailTemplates
    },
  ]
};

const authRoutes = {
  path: "/auth",
  name: "Auth",
  icon: UsersIcon,
  badgeColor: "secondary",
  badgeText: "Special",
  children: [
    {
      path: "/auth/sign-in",
      name: "Sign In",
      isPrivate: false,
      component: SignIn
    },
    {
      path: "/auth/sign-out",
      name: "Sign Out",
      isPrivate: false,
      component: SignOut
    },
    {
      path: "/auth/reset-password",
      name: "Reset Password",
      isPrivate: false,
      component: ResetPassword
    }
  ]
};

// ROUTES NOT VISIBLE IN THE SIDEBAR
const privateRoutes = {
  path: "/privacy",
  name: "Privacy",
  children: [
    {
      path: "/profile",
      name: "Profile",
      isPrivate: true,
      component: Profile
    },
    {
      path: "/profile-password",
      name: "Password",
      isPrivate: true,
      component: ProfilePassword
    },
    {
      path: "/profile-notifications",
      name: "Notifications",
      isPrivate: true,
      component: Notifications
    },
    {
      path: "/404",
      name: "404 Page",
      isPrivate: false,
      component: Page404
    },
    {
      path: "/500",
      name: "500 Page",
      isPrivate: false,
      component: Page500
    },
    {
      path: "/auth/sign-out",
      name: "sign out",
      isPrivate: true,
      component: SignOut
    }
  ]
};

// ADD  ROUTES - if not added here can not be visited
// Dashboard specific routes
export const dashboard = [
  dashboardRoutes,
  ordersRoutes,
  settingsRoutes,
  privateRoutes
];

// Landing specific routes
export const landing = [landingRoutes];

// Auth specific routes
export const page = [authRoutes];

// ROUTES TO SHOW IN NAVBAR
export default [
  dashboardRoutes,
  ordersRoutes,
  settingsRoutes,
];
