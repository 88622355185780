import React, { Component, Fragment} from "react";
import {Button, Card, CardHeader, CardTitle, Modal as ModalWindow, ModalHeader, ModalBody, ModalFooter} from "reactstrap";
import { Save as SaveIcon, XCircle as CancelIcon } from "react-feather";
import CardBody from "reactstrap/es/CardBody";
import { saveOrder, estimateShipping } from "../client/actions/apiActions";
import FormInput from "./form/FormInput";
import {stateList} from "../config";
import {connect} from "react-redux";
import Loader from "../components/Loader";

class DataEdit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dataResults: {},
            shippingRates: [],
            shippingInformation: '',
            loadingShipping: ''
        }
    }
    componentDidMount() {
        let dataResults={};
        dataResults["giftMessage"]       = this.props.dataResults.orderItems[0].giftMessage;
        dataResults["deliveryDate"]      = this.props.dataResults.orderItems[0].deliveryDate;
        dataResults["vaseSelection"]     = this.props.dataResults.orderItems[0].addons["Free Vase Selection"];
        dataResults["recipientName"]     = this.props.dataResults.orderItems[0].recipientName;
        dataResults["note"]              = this.props.dataResults.note;
        dataResults["shippingName"]      = this.props.dataResults.shippingName;
        dataResults["shippingAddress1"]  = this.props.dataResults.shippingAddress1;
        dataResults["shippingAddress2"]  = this.props.dataResults.shippingAddress2;
        dataResults["shippingCity"]      = this.props.dataResults.shippingCity;
        dataResults["shippingState"]     = this.props.dataResults.shippingState;
        dataResults["shippingZip"]       = this.props.dataResults.shippingZip;
        dataResults["shippingCountry"]   = this.props.dataResults.shippingCountry;
        this.setState({dataResults});
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps !== this.props)
        {
            if (this.props.data.status === 1 || this.props.data.status === '1')
            {
                console.log("componentDidUpdate", this.props.data.Rates);

                this.setState({
                   shippingRates: this.props.data.Rates,
                    loadingShipping: false
                });
            }
        }
    }

    modalOpen = (name) => {
        this.setState({ [name]: true });
    }
    modalClose = (name) => {
        this.setState({
            modalInputName: "",
            [name]: false
        });
    }

    onChange = (type, e, field) => {
        let dataResults = this.state.dataResults;
        let tmpValue = '';
        if (type === 'text')
        {
            dataResults[field.fieldName] = e.target.value;
        }
        else if (type === 'phone')
        {
            dataResults[field.fieldName] = e.target.value;
        }
        else if (type === 'email')
        {
            dataResults[field.fieldName] = e.target.value;
        }
        else if (type === 'textarea')
        {
            dataResults[field.fieldName] = e.target.value;
        }
        else if (type === 'toggle')
        {
            if (e.target.checked === true) dataResults[field.fieldName] = '1';
            if (e.target.checked === false) dataResults[field.fieldName] = '0';
        }
        else if (type === 'image')
        {
            dataResults[field.fieldName] = e;
        }
        else if (type === 'editor')
        {
            dataResults[field.fieldName] = e.target.getContent();
        }
        else if (type === 'select' || type === 'dropdown')
        {
            dataResults[e.target.name] = e.target.value;
        }
        else if (type === 'password')
        {
            dataResults[field.fieldName] = e.target.value;
        }
        else
        {
            dataResults[field.fieldName] = e.target.value;
        }
        if (typeof(field) !== 'undefined')
        {
            if (typeof(field.callBackFunction) !== "undefined")
            {
                console.log("callBackFunction", field.callBackFunction);
                this.props[field.callBackFunction](tmpValue);
            }
        }
        this.setState({dataResults: dataResults});
    };
    onSave = () => {
        this.props.saveOrder(this.props.dataResults.orderNumber, this.props.dataResults.subOrderNumber, this.state.dataResults);
    }
    checkRates = (orderId, subOrderId) => {
        this.setState({ loadingShipping: true });
        this.props.estimateShipping(orderId, subOrderId);
    }

    render() {
        const { title, slogan, dataResults } = this.props;
        const { loadingShipping, shippingRates } = this.state;
        return (<Card>
            <CardHeader>
                <CardTitle tag="h5">{title}</CardTitle>
                <h6 className="card-subtitle text-muted">{slogan}</h6>
            </CardHeader>
            <CardBody>
                <div className="container">
                    <div className="row mb-2">
                        <div className="col-12 text-right">
                            <Button onClick={() => this.props.onButtonCancel()} color="warning">
                                <CancelIcon size={18} />{' '}
                                Back
                            </Button>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-4">
                            <h4>Order Info:</h4>
                            Order Number: {dataResults.orderNumber}-{dataResults.subOrderNumber}<br/>
                            Order Date: {dataResults.dateCreated}<br/>
                            {dataResults.financialStatus && <Fragment>Financial Status: {dataResults.financialStatus}<br/></Fragment>}
                            {dataResults.fulfillmentStatus && <Fragment>Fulfillment Status: {dataResults.fulfillmentStatus}<br/></Fragment>}
                            <br/>

                            Sub Total: ${dataResults.orderSubTotal}<br/>
                            Tax: ${dataResults.orderTax}<br/>
                            Total: ${dataResults.orderTotal}<br/>
                        </div>
                        <div className="col-4">
                            <h4>Billing Information:</h4>
                            Ordered By: {dataResults.billingName}<br/>
                            Email: {dataResults.userEmail}<br/>
                            {dataResults.billingPhone && <Fragment>Phone: {dataResults.billingPhone}<br/></Fragment>}<br/>

                            <h4>Billing Address:</h4>
                            {dataResults.billingAddress1}<br/>
                            {dataResults.billingAddress2}{dataResults.billingAddress2 && <br/>}
                            {dataResults.billingCity}, {dataResults.billingState} {dataResults.billingZip}<br/>
                            {dataResults.billingCountry}<br/>
                            {dataResults.billingPhone}<br/>
                        </div>
                        <div className="col-4">
                            <h4>Shipping Information:</h4>
                            Ordered For: {dataResults.shippingName}<br/>
                            {dataResults.shippingPhone && <Fragment>Phone: {dataResults.shippingPhone}<br/></Fragment>}<br/>

                            <h4>Shipping Address:</h4>
                            {dataResults.shippingAddress1}<br/>
                            {dataResults.shippinggAddress2}{dataResults.shippinggAddress2 && <br/>}
                            {dataResults.shippingCity}, {dataResults.shippingState} {dataResults.shippingZip}<br/>
                            {dataResults.shippingCountry}<br/>
                            <Button onClick={() => this.modalOpen("modalShippingInformation")}>Edit Shipping Info</Button>
                        </div>
                    </div>
                    <hr/>
                    <div className="row">
                        <div className="col-12">
                            <h4>Order Notes:</h4>
                            {dataResults.note}<br/>
                            <Button onClick={() => this.modalOpen("modalNotes")}>Edit Notes</Button>
                        </div>
                    </div>
                    <hr/>
                    <div className="row">
                        <div className="col-12">
                            <h3>Order Item</h3>
                            <table className="table table-sm table-striped">
                                <thead className="thead-dark">
                                    <tr>
                                        <th>Qty</th>
                                        <th>Size</th>
                                        <th>Name</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>{dataResults.orderItems[0].qty}</td>
                                        <td>{dataResults.orderItems[0].variantTitle}</td>
                                        <td>{dataResults.orderItems[0].title}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-3">
                            <h4>Recipient Name: </h4>
                            {dataResults.orderItems[0].recipientName}<br/>
                            <br/>
                            <Button onClick={() => this.modalOpen("modalRecipientName")}>Edit Recipient</Button><br/>
                        </div>
                        <div className="col-3">
                            <h4>Vase Selection: </h4>
                            {dataResults.orderItems[0].addons["Free Vase Selection"]}<br/>
                            <br/>
                        </div>
                        <div className="col-3">
                            <h4>Delivery Date</h4>
                            Date: {dataResults.orderItems[0].deliveryDate}<br/>
                            Zip: {dataResults.orderItems[0].addons["Delivery Zip"]}<br/>
                            <br/>
                            <Button onClick={() => this.modalOpen("modalDeliveryDate")}>Edit Delivery Date</Button><br/>
                        </div>
                        <div className="col-3">
                            <h4>Gift Message</h4>
                            {dataResults.orderItems[0].giftMessage.replace("~=10=~", " ")}<br/><br/>
                            <Button onClick={() => this.modalOpen("modalGiftMessage")}>Edit Gift Message</Button><br/>
                        </div>
                    </div>
                    {dataResults.orderItems[0].imageURL && <Fragment>
                        <img src={dataResults.orderItems[0].imageURL} alt="tmp" width="250" /><br />
                        (can not update the image at this time)
                    </Fragment>}
                    <hr/>
                    <div className="row">
                        <div className="col-12">
                            <h3>Shipping Info</h3>
                            (clicking this button will check what shipping will be if it shipped today)<br/>
                            <Button onClick={() => this.checkRates(dataResults.orderNumber, dataResults.subOrderNumber)}>Check Rates</Button><br/>
                            {loadingShipping === true && <Loader />}
                            {loadingShipping === false && <table className="mt-3 table table-sm table-striped">
                                <thead className="thead-dark">
                                    <tr>
                                        <th>Service</th>
                                        <th>Rate</th>
                                        <th>Delivery Days</th>
                                        <th>Delivery Date</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {shippingRates.map((rate, index) => {
                                        return (<tr key={index}>
                                            <td>{rate.service}</td>
                                            <td>${rate.rate}</td>
                                            <td>{rate.delivery_days}</td>
                                            <td>{rate.delivery_date}</td>
                                        </tr>);
                                    })}
                                </tbody>
                            </table>}
                        </div>
                    </div>
                    <hr/>
                    <div className="row">
                        <div className="col-12">
                            <h3>Order Log</h3>
                            <table className="table table-sm table-striped">
                                <thead className="thead-dark">
                                    <tr>
                                        <th>Date</th>
                                        <th>Info</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {dataResults.orderLog.map((logItem, index) => {
                                        return (<tr key={index}>
                                            <td>{logItem.actionDate}</td>
                                            <td>{logItem.orderAction}</td>
                                        </tr>);
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </CardBody>
            <Modal title="Edit Gift Message" show={this.state.modalGiftMessage} onSave={() => this.onSave()} handleClose={() => this.modalClose("modalGiftMessage")} name={"modalGiftMessage"}>
                <FormInput
                    field={{fieldName: "giftMessage"}}
                    type={"textarea"}
                    id={"giftMessage"}
                    name={"giftMessage"}
                    label={"Gift Message"}
                    placeholder={'enter gift message'}
                    value={this.state.dataResults["giftMessage"]}
                    defaultValue={this.state.dataResults["giftMessage"]}
                    colSize={1}
                    onChange={this.onChange}
                />
            </Modal>
            <Modal title="Edit Delivery Date" show={this.state.modalDeliveryDate} onSave={() => this.onSave()} handleClose={() => this.modalClose("modalDeliveryDate")} name={"modalDeliveryDate"}>
                <FormInput
                    field={{fieldName: "deliveryDate"}}
                    type={"date"}
                    id={"deliveryDate"}
                    name={"deliveryDate"}
                    label={"Delivery Date"}
                    value={this.state.dataResults["deliveryDate"]}
                    defaultValue={this.state.dataResults["deliveryDate"]}
                    colSize={1}
                    onChange={this.onChange}
                />
            </Modal>
            <Modal title="Edit Recipient Name" show={this.state.modalRecipientName} onSave={() => this.onSave()} handleClose={() => this.modalClose("modalRecipientName")} name={"modalRecipientName"}>
                <FormInput
                    field={{fieldName: "recipientName"}}
                    type={"text"}
                    id={"recipientName"}
                    name={"recipientName"}
                    label={"Recipient Name"}
                    value={this.state.dataResults["recipientName"]}
                    defaultValue={this.state.dataResults["recipientName"]}
                    colSize={1}
                    onChange={this.onChange}
                />
            </Modal>
            <Modal title="Edit Vase Selection" show={this.state.modalVase} onSave={() => this.onSave()} handleClose={() => this.modalClose("modalVase")} name={"modalVase"}>
                <FormInput
                    field={{fieldName: "vaseSelection"}}
                    type={"text"}
                    id={"vaseSelection"}
                    name={"vaseSelection"}
                    label={"Vase Selection"}
                    value={this.state.dataResults["vaseSelection"]}
                    defaultValue={this.state.dataResults["vaseSelection"]}
                    colSize={1}
                    onChange={this.onChange}
                />
            </Modal>
            <Modal title="Edit Notes" show={this.state.modalNotes} onSave={() => this.onSave()} handleClose={() => this.modalClose("modalNotes")} name={"modalNotes"}>
                <FormInput
                    field={{fieldName: "note"}}
                    type={"textarea"}
                    id={"note"}
                    name={"note"}
                    label={"Order Notes"}
                    value={this.state.dataResults["note"]}
                    defaultValue={this.state.dataResults["note"]}
                    colSize={1}
                    onChange={this.onChange}
                />
            </Modal>
            <Modal title="Edit Shipping Address" show={this.state.modalShippingInformation} onSave={() => this.onSave()} handleClose={() => this.modalClose("modalShippingInformation")} name={"modalShippingInformation"}>
                <FormInput
                    field={{fieldName: "shippingName"}}
                    type={"text"}
                    id={"shippingName"}
                    name={"shippingName"}
                    label={"Ship To Name"}
                    value={this.state.dataResults["shippingName"]}
                    defaultValue={this.state.dataResults["shippingName"]}
                    colSize={1}
                    onChange={this.onChange}
                />

                <FormInput
                    field={{fieldName: "shippingAddress1"}}
                    type={"text"}
                    id={"shippingAddress1"}
                    name={"shippingAddress1"}
                    label={"Address 1"}
                    value={this.state.dataResults["shippingAddress1"]}
                    defaultValue={this.state.dataResults["shippingAddress1"]}
                    colSize={1}
                    onChange={this.onChange}
                />

                <FormInput
                    field={{fieldName: "shippingAddress2"}}
                    type={"text"}
                    id={"shippingAddress2"}
                    name={"shippingAddress2"}
                    label={"Address 2"}
                    value={this.state.dataResults["shippingAddress2"]}
                    defaultValue={this.state.dataResults["shippingAddress2"]}
                    colSize={1}
                    onChange={this.onChange}
                />

                <FormInput
                    field={{fieldName: "shippingCity"}}
                    type={"text"}
                    id={"shippingCity"}
                    name={"shippingCity"}
                    label={"City"}
                    value={this.state.dataResults["shippingCity"]}
                    defaultValue={this.state.dataResults["shippingCity"]}
                    colSize={1}
                    onChange={this.onChange}
                />

                <FormInput
                    field={{fieldName: "shippingState"}}
                    type={"dropdown"}
                    id={"shippingState"}
                    name={"shippingState"}
                    dropdown={stateList}
                    label={"State"}
                    value={this.state.dataResults["shippingState"]}
                    defaultValue={this.state.dataResults["shippingState"]}
                    colSize={1}
                    onChange={this.onChange}
                />

                <FormInput
                    field={{fieldName: "shippingZip"}}
                    type={"text"}
                    id={"shippingZip"}
                    name={"shippingZip"}
                    label={"Zip"}
                    value={this.state.dataResults["shippingZip"]}
                    defaultValue={this.state.dataResults["shippingZip"]}
                    colSize={1}
                    onChange={this.onChange}
                />

            </Modal>
        </Card>);
    }
}

const Modal = ({ handleClose, show, children, name, title, onSave, className }) => {
    return (<ModalWindow isOpen={show} toggle={() => handleClose(name)} className={className}>
        <ModalHeader toggle={() => handleClose(name)}>{title}</ModalHeader>
        <ModalBody>
            {children}
        </ModalBody>
        <ModalFooter>
            <Button color="primary" onClick={() => onSave()}>Update</Button>{' '}
            <Button color="secondary" onClick={() => handleClose(name)}>Cancel</Button>
        </ModalFooter>
    </ModalWindow>);
};


const mapStateToProps = state => ({
    auth: state.auth.user,
    data: state.pages.data,
});

export default connect(mapStateToProps, {saveOrder, estimateShipping})(DataEdit);