import React from "react";
import { Link } from "react-router-dom";

import {
  Button,
  Card,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input
} from "reactstrap";
import logo from "../../assets/img/logo/logo.png";

const ResetPassword = () => (
  <React.Fragment>
    <div className="text-center mt-4">
      <img
          src={logo}
          alt="Coming Home by More Too Life, Inc"
          className="img-fluid rounded-circle"
          width="132"
          height="132"
      />
      <h2>Coming Home</h2>
      <small>Mentor Management App</small>
    </div>

    <Card>
      <CardBody>
        <div className="m-sm-4">
          <div className="text-center">
            <h1 className="h2">Reset password</h1>
            <p className="lead">Enter your email to reset your password.</p>
          </div>
          <Form>
            <FormGroup>
              <Label>Email</Label>
              <Input
                bsSize="lg"
                type="email"
                name="email"
                placeholder="Enter your email"
              />
            </FormGroup>
            <div className="text-center mt-3">
              <Link to="/dashboard">
                <Button color="primary" size="lg">
                  Reset password
                </Button>
              </Link>
              <br/>
              <br/>
              <Link to="/auth/sign-in">
                <Button color="secondary" size="sm">
                  Back
                </Button>
              </Link>
            </div>
          </Form>
        </div>
      </CardBody>
    </Card>
  </React.Fragment>
);

export default ResetPassword;
