import en from './languages/en.json';
import es from './languages/es.json';
export const defaultLang = "en";
export const ApiURL = "https://fa.overnightflowers.com/v1/apicalls";
export const BaseImageURL = "https://fa.overnightflowers.com";
export const ChatServer = "https://chat.overnightflowers.com:3000/";
export const WebsiteURL = window.location.host;
export const timezones = [
    {
        id: 1,
        name: 'GMT - Greenwich Mean Time - +0'
    },
    {
        id: 2,
        name: 'UTC - Universal Coordinated Time - 0'
    },
    {
        id: 3,
        name: 'ECT - European Central Time - GMT+1:00'
    },
    {
        id: 4,
        name: 'EET - Eastern European Time - GMT+2:00'
    },
    {
        id: 5,
        name: 'ART - (Arabic) Egypt Standard Time - GMT+2:00'
    },
    {
        id: 6,
        name: 'MET - Middle East Time - GMT+3:30'
    },
    {
        id: 7,
        name: 'NET - Near East Time - GMT+4:00'
    },
    {
        id: 8,
        name: 'PLT - Pakistan Lahore Time - GMT+5:00'
    },
    {
        id: 9,
        name: 'IST - India Standard Time - GMT+5:30'
    },
    {
        id: 10,
        name: 'BST - Bangladesh Standard Time - GMT+6:00'
    },
    {
        id: 11,
        name: 'VST - Vietnam Standard Time - GMT+7:00'
    },
    {
        id: 12,
        name: 'CTT - China Taiwan Time - GMT+8:00'
    },
    {
        id: 13,
        name: 'JST - Japan Standard Time - GMT+9:00'
    },
    {
        id: 14,
        name: 'ACT - Australia Central Time - GMT+9:30'
    },
    {
        id: 15,
        name: 'AET - Australia Eastern Time - GMT+10:00'
    },
    {
        id: 16,
        name: 'SST - Solomon Standard Time - GMT+11:00'
    },
    {
        id: 17,
        name: 'NST - New Zealand Standard Time - GMT+12:00'
    },
    {
        id: 18,
        name: 'MIT - Midway Islands Time - GMT-11:00'
    },
    {
        id: 19,
        name: 'HST - Hawaii Standard Time - GMT-10:00'
    },
    {
        id: 20,
        name: 'AST - Alaska Standard Time - GMT-9:00'
    },
    {
        id: 21,
        name: 'PST - Pacific Standard Time - GMT-8:00'
    },
    {
        id: 22,
        name: 'PNT - Phoenix Standard Time - GMT-7:00'
    },
    {
        id: 23,
        name: 'MST - Mountain Standard Time - GMT-7:00'
    },
    {
        id: 24,
        name: 'CST - Central Standard Time - GMT-6:00'
    },
    {
        id: 25,
        name: 'EST - Eastern Standard Time - GMT-5:00'
    },
    {
        id: 26,
        name: 'IET - Indiana Eastern Standard Time - GMT-5:00'
    },
    {
        id: 27,
        name: 'PRT - Puerto Rico and US Virgin Islands Time - GMT-4:00'
    },
    {
        id: 28,
        name: 'CNT - Canada Newfoundland Time - GMT-3:30'
    },
    {
        id: 29,
        name: 'AGT - Argentina Standard Time - GMT-3:00'
    },
    {
        id: 30,
        name: 'BET - Brazil Eastern Time - GMT-3:00'
    },
    {
        id: 31,
        name: 'CAT - Central African Time - GMT-1:00'
    }
];
export const GoogleMapsAPIKey = "AIzaSyCZcNpWkRbGHSMSSWzb-DilZAXvi8XZtFo";
export const agencyTypes = [];
export const serviceAreas = [
    {
        id: 25,
        name: 25,
        value: 25
    },
    {
        id: 50,
        name: 50,
        value: 50
    },
    {
        id: 100,
        name: 100,
        value: 100
    }
];
export const languages = [
    {
        id: 'en',
        name: "English",
        value: "English"
    },
    {
        id: 'es',
        name: "Español",
        value: "Español"
    },
];

export const languageText = { en, es };
export const stateList = [
    {
        "name": "Alabama",
        "abbreviation": "AL"
    },
    {
        "name": "Alaska",
        "abbreviation": "AK"
    },
    {
        "name": "American Samoa",
        "abbreviation": "AS"
    },
    {
        "name": "Arizona",
        "abbreviation": "AZ"
    },
    {
        "name": "Arkansas",
        "abbreviation": "AR"
    },
    {
        "name": "California",
        "abbreviation": "CA"
    },
    {
        "name": "Colorado",
        "abbreviation": "CO"
    },
    {
        "name": "Connecticut",
        "abbreviation": "CT"
    },
    {
        "name": "Delaware",
        "abbreviation": "DE"
    },
    {
        "name": "District Of Columbia",
        "abbreviation": "DC"
    },
    {
        "name": "Federated States Of Micronesia",
        "abbreviation": "FM"
    },
    {
        "name": "Florida",
        "abbreviation": "FL"
    },
    {
        "name": "Georgia",
        "abbreviation": "GA"
    },
    {
        "name": "Guam",
        "abbreviation": "GU"
    },
    {
        "name": "Hawaii",
        "abbreviation": "HI"
    },
    {
        "name": "Idaho",
        "abbreviation": "ID"
    },
    {
        "name": "Illinois",
        "abbreviation": "IL"
    },
    {
        "name": "Indiana",
        "abbreviation": "IN"
    },
    {
        "name": "Iowa",
        "abbreviation": "IA"
    },
    {
        "name": "Kansas",
        "abbreviation": "KS"
    },
    {
        "name": "Kentucky",
        "abbreviation": "KY"
    },
    {
        "name": "Louisiana",
        "abbreviation": "LA"
    },
    {
        "name": "Maine",
        "abbreviation": "ME"
    },
    {
        "name": "Marshall Islands",
        "abbreviation": "MH"
    },
    {
        "name": "Maryland",
        "abbreviation": "MD"
    },
    {
        "name": "Massachusetts",
        "abbreviation": "MA"
    },
    {
        "name": "Michigan",
        "abbreviation": "MI"
    },
    {
        "name": "Minnesota",
        "abbreviation": "MN"
    },
    {
        "name": "Mississippi",
        "abbreviation": "MS"
    },
    {
        "name": "Missouri",
        "abbreviation": "MO"
    },
    {
        "name": "Montana",
        "abbreviation": "MT"
    },
    {
        "name": "Nebraska",
        "abbreviation": "NE"
    },
    {
        "name": "Nevada",
        "abbreviation": "NV"
    },
    {
        "name": "New Hampshire",
        "abbreviation": "NH"
    },
    {
        "name": "New Jersey",
        "abbreviation": "NJ"
    },
    {
        "name": "New Mexico",
        "abbreviation": "NM"
    },
    {
        "name": "New York",
        "abbreviation": "NY"
    },
    {
        "name": "North Carolina",
        "abbreviation": "NC"
    },
    {
        "name": "North Dakota",
        "abbreviation": "ND"
    },
    {
        "name": "Northern Mariana Islands",
        "abbreviation": "MP"
    },
    {
        "name": "Ohio",
        "abbreviation": "OH"
    },
    {
        "name": "Oklahoma",
        "abbreviation": "OK"
    },
    {
        "name": "Oregon",
        "abbreviation": "OR"
    },
    {
        "name": "Palau",
        "abbreviation": "PW"
    },
    {
        "name": "Pennsylvania",
        "abbreviation": "PA"
    },
    {
        "name": "Puerto Rico",
        "abbreviation": "PR"
    },
    {
        "name": "Rhode Island",
        "abbreviation": "RI"
    },
    {
        "name": "South Carolina",
        "abbreviation": "SC"
    },
    {
        "name": "South Dakota",
        "abbreviation": "SD"
    },
    {
        "name": "Tennessee",
        "abbreviation": "TN"
    },
    {
        "name": "Texas",
        "abbreviation": "TX"
    },
    {
        "name": "Utah",
        "abbreviation": "UT"
    },
    {
        "name": "Vermont",
        "abbreviation": "VT"
    },
    {
        "name": "Virgin Islands",
        "abbreviation": "VI"
    },
    {
        "name": "Virginia",
        "abbreviation": "VA"
    },
    {
        "name": "Washington",
        "abbreviation": "WA"
    },
    {
        "name": "West Virginia",
        "abbreviation": "WV"
    },
    {
        "name": "Wisconsin",
        "abbreviation": "WI"
    },
    {
        "name": "Wyoming",
        "abbreviation": "WY"
    }
];