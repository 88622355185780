import React, {Component} from "react";
import {Container } from "reactstrap";
import DataList  from "./DataList";
import DataEdit  from "./DataEdit";
import { getTable, viewRecord, getRecord, saveRecord, archiveRecord, deleteRecord } from "../client/actions/apiActions";
import {connect} from "react-redux";
import Loader from "../components/Loader";
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css';
import {toastr} from "react-redux-toastr";

class DataOverview extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pageMode: "loading",
            pageTitle: '',
            showExportData: true,
            listTitle: '',
            addTitle: '',
            editTitle: '',
            listSubTitle: '',
            addSubTitle: '',
            editSubTitle: '',
            tableColumns : [],
            tableData: [],
            fieldData: [],
            fieldDataResults: {},
            fieldDataDefault: {},
            fieldDataErrors: [],
            totalPages: 1,
            totalRecords: 0,
            searchText: '',
            changeValue: '',
            currentPage: 1,
            recordsPerPage: 25,
            sortDirection: 'ASC',
            sortFieldName: 'default',
            settings: {},

            timeOut: 5000,
            showCloseButton: true,
            progressBar: true,
            position: "top-right"
        }
    }
    componentDidMount() {
        let fieldDataResults = [];
        let settings = {};
        let singleEdit = this.props.singleEdit;
        if (singleEdit !== true) singleEdit = false;

        let showExportData = this.props.showExportData;
        if (showExportData !== false) showExportData = true;

        if (singleEdit === true) {
            fieldDataResults = this.props.fieldDataResults;
        }
        if (typeof(this.props.settings) !== 'undefined') {
            settings = this.props.settings;
        }

        let recordsPerPage = this.state.recordsPerPage;
        if (typeof(this.props.recordsPerPage) !== 'undefined') {
            recordsPerPage = this.props.recordsPerPage;
        }

        let sortFieldName = this.state.sortFieldName;
        if (typeof(this.props.sortFieldName) !== 'undefined') {
            sortFieldName = this.props.sortFieldName;
        }

        let sortDirection = this.state.sortDirection;
        if (typeof(this.props.sortDirection) !== 'undefined') {
            sortDirection = this.props.sortDirection;
        }

        this.setState({
            singleEdit: singleEdit,
            fieldDataResults: fieldDataResults,
            tableName: this.props.tableName,
            showExportData: showExportData,
            settings: settings,
            sortFieldName: sortFieldName,
            sortDirection: sortDirection,

            pageTitle: this.props.pageTitle,
            listTitle: this.props.listTitle,
            listSubTitle: this.props.listSubTitle,
            addTitle: this.props.addTitle,
            addSubTitle: this.props.addSubTitle,
            editTitle: this.props.editTitle,
            editSubTitle: this.props.editSubTitle,

            tableColumns : this.props.tableColumns,
            fieldData: this.props.fieldData,
            recordsPerPage: recordsPerPage
        });
        if (singleEdit === false) this.props.getTable(this.props.tableName, 1, recordsPerPage, sortFieldName, sortDirection);
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps !== this.props)
        {
            if (this.props.data.dataType === "addRecord")
            {
                this.setState({
                    fieldDataResults: this.props.data.dataValues,
                    pageMode: "add"
                });
            }
            else if (this.props.data.dataType === "getRecord")
            {
                this.setState({
                    fieldDataResults: this.props.data.dataValues,
                    pageMode: "edit"
                });
            }
            else if (this.props.data.dataType === "viewRecord")
            {
                this.setState({
                    fieldDataResults: this.props.data.dataValues,
                    pageMode: "view"
                });
            }
            else if (this.props.data.dataType === "deleteRecord")
            {
                this.refreshFunction();
            }
            else if (this.props.data.dataType === "saveRecord")
            {
                if (this.props.data.status === 1) {
                    this.showToastr("success", "Save Successful", "Record was successfully saved.");
                } else {
                    this.showToastr("danger", "Error Saving", this.props.data.message);
                }
                if (this.state.singleEdit !== true) {
                    this.refreshFunction();
                } else {
                    this.props.getRecord(this.state.tableName, this.props.data.recordId);
                }
            }
            else if (this.props.data.dataType === "archiveRecord")
            {
                this.refreshFunction();
            }
            else if (this.props.data.dataType === "getTable")
            {
                this.setState({
                    tableData: this.props.data.dataValues,
                    currentPage: this.props.data.currentPage,
                    totalPages: this.props.data.totalPages,
                    totalRecords: this.props.data.totalRecords,
                    pageMode: "list"
                });
            }
        }
    }
    refreshFunction = (currentPage, recordsPerPage, sortFieldName, sortDirection, searchText) => {
        this.setState({ pageMode: "loading" });
        if (typeof(currentPage) === 'undefined') currentPage = this.state.currentPage;
        if (typeof(recordsPerPage) === 'undefined') recordsPerPage = this.state.recordsPerPage;
        if (typeof(sortFieldName) === 'undefined') sortFieldName = this.state.sortFieldName;
        if (typeof(sortDirection) === 'undefined') sortDirection = this.state.sortDirection;
        if (typeof(searchText) === 'undefined') searchText = this.state.searchText;

        this.props.getTable(this.state.tableName, currentPage, recordsPerPage, sortFieldName, sortDirection, searchText);
    };
    addFunction = () => {
        this.setState({ pageMode: "loading" });
        this.props.getRecord(this.state.tableName, '0');
    }
    editFunction = (id) => {
        this.setState({ pageMode: "loading" });
        this.props.getRecord(this.state.tableName, id);
    }
    viewFunction = (id) => {
        this.setState({ pageMode: "loading" });
        this.props.viewRecord(this.state.tableName, id);
    }
    deleteFunction = (id) => {
        this.props.deleteRecord(this.state.tableName, id);
    }
    archiveRecord = (id) => {
        this.props.archiveRecord(this.state.tableName, id);
    }
    updatePage = (currentPage) => {
        this.setState({currentPage: currentPage});
        this.refreshFunction(currentPage, this.state.recordsPerPage);
    };
    deleteConfirmFunction = (id) => {
        confirmAlert({
            title: 'Confirm Deletion',
            message: 'Are you sure you wish to delete?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => this.deleteFunction(id)
                },
                {
                    label: 'No'
                }
            ]
        });
    };
    saveFunction = (id, data) => {
        let errors = 0;
        let fieldDataErrors = this.state.fieldDataErrors;
        const { fieldData } = this.state;
        fieldData.map((field, index) => {
            if (field.required === true && (data[field.fieldName] === '' || typeof(data[field.fieldName]) === 'undefined')) {
                console.log("show error");
                errors=1;
                fieldDataErrors[field.fieldName] = field.requiredMessage;
            }
            return (null);
        });
        if (errors === 1) {
            this.setState({fieldDataErrors: fieldDataErrors});
        } else {
            this.props.saveRecord(this.state.tableName, id, data);
        }
    }
    exportFunction = (id, data) => {
        alert("not implemented yet, coming soon");
    }
    changeValue = (name, e) => {
        let value = e.target.value;
        this.setState({[name]: value});
    };
    searchKeyPress = (e) => {
        if (e.key === 'Enter') {
            this.searchOverview();
        }
    };
    resetSearch = () => {
        this.setState({searchText: "", pageMode: "loading"});
        let searchText = "";
        let currentPage = this.state.currentPage;
        let recordsPerPage = this.state.recordsPerPage;
        let sortFieldName = this.state.sortFieldName;
        let sortDirection = this.state.sortDirection;
        this.refreshFunction(currentPage, recordsPerPage, sortFieldName, sortDirection, searchText);
    };
    searchOverview = () => {
        this.setState({ pageMode: "loading" });
        let searchText = this.state.searchText;
        let currentPage = 1;
        let recordsPerPage = this.state.recordsPerPage;
        let sortFieldName = this.state.sortFieldName;
        let sortDirection = this.state.sortDirection;
        this.refreshFunction(currentPage, recordsPerPage, sortFieldName, sortDirection, searchText);
    };
    closeFunction = () => {
        this.setState({ pageMode: "loading" });
        this.refreshFunction();
    }
    changeRecordsPerPage = (e) => {
        this.setState({[e.target.name]: e.target.value, currentPage: 1});
        this.refreshFunction(1, e.target.value)
    };
    changeSort = (sortFieldName, sortDirection) => {
        if (sortDirection === "DESC") sortDirection = "ASC";
        else sortDirection = "DESC";
        this.setState({ pageMode: "loading", sortFieldName, sortDirection });
        this.refreshFunction(this.state.currentPage, this.state.recordsPerPage, sortFieldName, sortDirection);
    };
    showToastr = (type, title, message) => {
        const options = {
            timeOut: parseInt(this.state.timeOut),
            showCloseButton: this.state.showCloseButton,
            progressBar: this.state.progressBar,
            position: this.state.position
        };
        const toastrInstance =
            type === "info"
                ? toastr.info
                : type === "warning"
                ? toastr.warning
                : type === "error"
                    ? toastr.error
                    : toastr.success;

        toastrInstance(
            title,
            message,
            options
        );
    }

    render() {
        let pageMode = this.state.pageMode;
        let languageTable = this.props.languageTable;
        const { tableColumns, tableData, fieldData, fieldDataResults, sortFieldName, sortDirection, fieldDataErrors, singleEdit, currentPage, totalPages, totalRecords } = this.state;
        const { pageTitle, addTitle, listTitle, listSubTitle, tableName, recordsPerPage, searchText, settings } = this.state;
        if (typeof(languageTable) === 'undefined') languageTable = tableName;
        if (singleEdit === true) pageMode="edit";
        if (pageMode === "loading") {
            return (<Container fluid className="p-0">
                <h1 className="h3 mb-3">{pageTitle}</h1>
                <Loader />
            </Container>);
        }
        if (pageMode === "list") {
            return (<Container fluid className="p-0">
                <h1 className="h3 mb-3">{pageTitle}</h1>
                <DataList
                    title={listTitle}
                    addTitle={addTitle}
                    slogan={listSubTitle}
                    columns={tableColumns}
                    tableData={tableData}
                    tableName={tableName}
                    languageTable={languageTable}
                    sortFieldName={sortFieldName}
                    sortDirection={sortDirection}
                    searchText={searchText}
                    settings={settings}
                    currentPage={currentPage}
                    totalPages={totalPages}
                    totalRecords={totalRecords}
                    recordsPerPage={recordsPerPage}

                    searchKeyPress={this.searchKeyPress}
                    resetSearch={this.resetSearch}
                    changeValue={this.changeValue}
                    searchOverview={this.searchOverview}
                    onUpdatePage={this.updatePage}
                    onAdd={this.addFunction}
                    onExport={this.exportFunction}
                    onChangeRecordsPerPage={this.changeRecordsPerPage}
                    changeSort={this.changeSort}
                    onEdit={this.editFunction}
                    onView={this.viewFunction}
                    onDelete={this.deleteConfirmFunction}
                    onArchive={this.archiveRecord}
                />
            </Container>);
        }
        if (pageMode === "add" || pageMode === "view" || pageMode === "edit") {
            return (<Container fluid className="p-0">
                <h1 className="h3 mb-3">{pageTitle}</h1>
                <DataEdit
                    mode={pageMode}
                    singleEdit={singleEdit}
                    title={this.state[pageMode + "Title"]}
                    slogan={this.state[pageMode + "SubTitle"]}
                    tableName={tableName}
                    languageTable={languageTable}
                    settings={settings}
                    dataFields={fieldData}
                    dataResults={fieldDataResults}
                    dataErrors={fieldDataErrors}
                    onSave={this.saveFunction}
                    onCancel={this.closeFunction}
                />
            </Container>);
        }
    }
}
const mapStateToProps = state => ({
    auth: state.auth.user,
    data: state.pages.data,
});
export default connect(mapStateToProps, {getTable, viewRecord, getRecord, saveRecord, archiveRecord, deleteRecord} )(DataOverview);