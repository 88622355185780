import React, {Fragment, Component} from "react";
import {Button, Card, CardHeader, CardTitle, Table} from "reactstrap";
import {
    ChevronDown as SortDown, Download as DownloadIcon, Search as SearchIcon, Users as UsersIcons,
    XOctagon as ResetIcon,ChevronUp as SortUp, Edit as EditIcon, Trash2 as DeleteIcon,
    PlusSquare as AddIcon, CheckCircle as ActiveIcon, XCircle as InActiveIcon, Eye as ArchiveIcon,
    Mail as EmailIcon
} from "react-feather";
import {BaseImageURL, languageText} from "../config";
import {connect} from "react-redux";
import { Link } from "react-router-dom";

class DataList extends Component {
    render() {
        const { tableData, settings, searchText, tableName, columns, title, sortFieldName, searchKeyPress, resetSearch, sortDirection, slogan, onAdd, onViewsUsers, changeValue, searchOverview, onView, onExport, recordsPerPage, addTitle, onChangeRecordsPerPage, changeSort, onEdit, onDelete, onArchive, onUpdatePage, currentPage, totalPages, totalRecords } = this.props;
        const {  languageCode } = this.props.auth.user;
        let hasStatus = false;
        if (settings.showCard === false)
        {
            return (<Fragment>
                <h6 className="card-subtitle text-muted">{slogan}</h6><br/>
                <div className="row align-items-center">
                    <div className="col-12 col-sm text-left mb-2">
                        <Button color="primary" onClick={() => onAdd() }>
                            <AddIcon size={18} /> {' '}
                            {addTitle}
                        </Button>
                        {typeof(settings) !== 'undefined' && <Fragment>
                            {settings.showExport !== false && <Button color="secondary" onClick={() => onExport() } className="ml-1">
                                <DownloadIcon size={18} /> {' '}
                                Export Data
                            </Button>}
                        </Fragment>}
                    </div>
                    {typeof(settings) !== 'undefined' && <Fragment>
                        {settings.showSearch !== false && <div className="col-12 col-sm">
                            <div className="row align-items-center">
                                <div className="col-12">
                                    <div className="input-group">
                                        <div className="input-group-prepend"><span className="input-group-text" id="basic-addon3">Search Term:</span></div>
                                        <input
                                            id="searchText"
                                            name="searchText"
                                            value={searchText}
                                            className="form-control"
                                            onKeyPress={(e) => { searchKeyPress(e); } }
                                            onChange={(e) => changeValue('searchText', e)}
                                        />
                                        <div className="input-group-append">
                                            <Button color="success" onClick={() => searchOverview() }>
                                                <SearchIcon size={18} /> {' '}
                                                Search
                                            </Button>
                                        </div>
                                        <div className="input-group-append">
                                            <Button color="secondary" onClick={() => resetSearch() }>
                                                <ResetIcon size={18} /> {' '}
                                                Reset
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>}
                    </Fragment>}
                </div>
                <div className="table-responsive">
                    <Table striped hover size="sm">
                        <thead>
                            <tr>
                                <td colSpan={columns.length-1}>
                                    <DataListPages
                                        currentPage={currentPage}
                                        totalPages={totalPages}
                                        onUpdatePage={onUpdatePage}
                                    />
                                </td>
                                <td className="text-right">
                                    <select
                                        className='form-control'
                                        id="recordsPerPage"
                                        name="recordsPerPage"
                                        value={recordsPerPage}
                                        defaultValue={recordsPerPage}
                                        onChange={(e) => onChangeRecordsPerPage(e)}
                                        style={{maxWidth: '120px', display: 'inline'}}
                                    >
                                        <option value="">-- SELECT --</option>
                                        <option value="5">5</option>
                                        <option value="25">25</option>
                                        <option value="50">50</option>
                                        <option value="100">100</option>
                                        <option value="10000">all</option>
                                    </select>
                                </td>
                            </tr>
                        </thead>
                        <thead className="table-dark">
                            <tr>
                                {columns && <Fragment>
                                    { columns.map((column, index) => {
                                        if (column.dataField === 'active' || column.dataField === 'status') hasStatus = true;
                                        if (column.type === "bool") {
                                            if (column.sort ===  true) {
                                                return (<th key={index} className="text-center cursor-pointer">{languageText[languageCode].components[tableName].columns[column.dataField]}</th>);
                                            } else {
                                                return (<th key={index} className="text-center">{languageText[languageCode].components[tableName].columns[column.dataField]}</th>);
                                            }
                                        } else {
                                            if (column.sort ===  true) {
                                                return (<th key={index} className="cursor-pointer" onClick={() => changeSort(column.dataField, sortDirection) }>
                                                    {sortFieldName === column.dataField && typeof(sortDirection) !== 'undefined' && sortDirection === 'ASC' && <SortDown size={12} />}
                                                    {sortFieldName === column.dataField && typeof(sortDirection) !== 'undefined' && sortDirection === 'DESC' && <SortUp size={12} />}
                                                    {languageText[languageCode].components[tableName].columns[column.dataField]}
                                                </th>);
                                            } else {
                                                if (column.dataField === 'tools') {
                                                    return (<th key={index} className="text-right">{languageText[languageCode].components[tableName].columns[column.dataField]}</th>);
                                                } else {
                                                    return (<th key={index}>{languageText[languageCode].components[tableName].columns[column.dataField]}</th>);
                                                }
                                            }
                                        }
                                    })}
                                </Fragment>}
                            </tr>
                        </thead>
                        <tbody>
                        {tableData && <Fragment>
                            {tableData.length !== 0 && <Fragment>
                                {tableData.map((dataRow, index) => {
                                    return (<tr key={index}>
                                        {columns.map((column, index) => {
                                            let value = dataRow[column.dataField];
                                            if (column.dataField === "tools")
                                            {
                                                return (<td key={index} nowrap="true" style={{width: 220}} className="text-right">
                                                    {settings.showView === true &&    <Fragment><Button size="sm" color="primary" onClick={() => onView(dataRow.id)}><ArchiveIcon size={18} /></Button>{' '}</Fragment>}
                                                    {tableName === "organizations" && <Fragment><Button size="sm" color="success" onClick={() => onViewsUsers(dataRow.id)}><UsersIcons size={18} /></Button>{' '}</Fragment>}
                                                    {tableName === "users" && dataRow.status === 2 && <Fragment><Button size="sm" color="success" onClick={() => onViewsUsers(dataRow.id)}><EmailIcon size={18} /></Button>{' '}</Fragment>}
                                                    {(dataRow.editButton === "true" || dataRow.editButton === true) && settings.showEdit !== false && <Fragment>
                                                        {tableName !== "mentee" && <Button size="sm" color="primary" onClick={() => onEdit(dataRow.id)}><EditIcon size={18} /></Button>}
                                                        {tableName === "mentee" && <Link to={`/mentee/manage/${dataRow.id}`} className="btn btn-sm btn-primary" onClick={() => onEdit(dataRow.id)}><EditIcon size={18} /></Link>}
                                                    </Fragment>}{' '}
                                                    {(dataRow.deleteButton === "true" || dataRow.deleteButton === true) && settings.showDelete !== false && <Button size="sm" color="danger" onClick={() => onDelete(dataRow.id)}><DeleteIcon size={18} /></Button>}{' '}
                                                    {hasStatus === true && settings.showArchive !== false && <Button size="sm" color="warning" onClick={() => onArchive(dataRow.id)}><ArchiveIcon size={18} /></Button>}
                                                </td>);
                                            }
                                            else if (column.type === "bool")
                                            {
                                                return (<td key={index} style={{width: 75}} className="text-center">
                                                    {(value === 1 || value === '1' || value === true || value === "true") && <ActiveIcon size={18}/>}
                                                    {(value === 0 || value === '0' || value === false || value === "false") && <InActiveIcon size={18}/>}
                                                </td>);
                                            }
                                            else if (column.type === "image")
                                            {
                                                if (value !== '') {
                                                    return (<td key={index}>
                                                        {value && <img src={value} style={{maxWidth: 60}} alt={value} border="0" />}
                                                    </td>);
                                                } else {
                                                    return (<td key={index}>&nbsp;</td>);
                                                }
                                            }
                                            else
                                            {
                                                return (<td key={index} dangerouslySetInnerHTML={{__html: value}} />)
                                            }
                                        })}
                                    </tr>);
                                })}
                            </Fragment>}
                            {tableData.length === 0 && <Fragment>
                                <tr>
                                    <td colSpan={columns.length} className="text-center">
                                        {languageText[languageCode].buttons["noRecords"]}
                                    </td>
                                </tr>
                            </Fragment>}
                        </Fragment>}
                        </tbody>
                        <tfoot>
                            <tr><td colSpan={columns.length} /></tr>
                            <tr>
                                <td colSpan={columns.length-1} className="pt-4">
                                    <DataListPages
                                        currentPage={currentPage}
                                        totalPages={totalPages}
                                        onUpdatePage={onUpdatePage}
                                    />
                                </td>
                                <td className="text-right pt-4">
                                    <select
                                        className='form-control'
                                        id="recordsPerPage"
                                        name="recordsPerPage"
                                        value={recordsPerPage}
                                        defaultValue={recordsPerPage}
                                        onChange={(e) => onChangeRecordsPerPage(e)}
                                        style={{maxWidth: '120px', display: 'inline'}}
                                    >
                                        <option value="">-- SELECT --</option>
                                        <option value="5">5</option>
                                        <option value="25">25</option>
                                        <option value="50">50</option>
                                        <option value="100">100</option>
                                        <option value="10000">all</option>
                                    </select>
                                </td>
                            </tr>
                            <tr>
                                <td colSpan={columns.length}>
                                    Total Pages : {totalPages} -
                                    Total Records : {totalRecords}
                                </td>
                            </tr>
                        </tfoot>
                    </Table>
                </div>
            </Fragment>);
        }
        else
        {
            return (<Card>
                <CardHeader>
                    <CardTitle tag="h5">{title}</CardTitle>
                    <h6 className="card-subtitle text-muted">{slogan}</h6><br/>
                    <div className="row align-items-center">
                        <div className="col-12 col-sm text-left">
                            {settings.showAdd !== false && <Button color="primary" onClick={() => onAdd() }>
                                <AddIcon size={18} /> {' '}
                                {addTitle}
                            </Button>}

                            {typeof(settings) !== 'undefined' && <Fragment>
                                {settings.showExport !== false && <Button color="secondary" onClick={() => onExport() } className="ml-1">
                                    <DownloadIcon size={18} /> {' '}
                                    Export Data
                                </Button>}
                            </Fragment>}
                        </div>

                        {typeof(settings) !== 'undefined' && <Fragment>
                            {settings.showSearch !== false && <div className="col-12 col-sm">
                                <div className="row align-items-center">
                                    <div className="col-12">
                                        <div className="input-group">
                                            <div className="input-group-prepend"><span className="input-group-text" id="basic-addon3">Search Term:</span></div>
                                            <input
                                                id="searchText"
                                                name="searchText"
                                                value={searchText}
                                                className="form-control"
                                                onKeyPress={(e) => { searchKeyPress(e); } }
                                                onChange={(e) => changeValue('searchText', e)}
                                            />
                                            <div className="input-group-append">
                                                <Button color="success" onClick={() => searchOverview() }>
                                                    <SearchIcon size={18} /> {' '}
                                                    Search
                                                </Button>
                                            </div>
                                            <div className="input-group-append">
                                                <Button color="secondary" onClick={() => resetSearch() }>
                                                    <ResetIcon size={18} /> {' '}
                                                    Reset
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>}
                        </Fragment>}

                    </div>
                </CardHeader>
                <div className="table-responsive">
                    <Table striped hover size="sm">
                        <thead>
                            <tr>
                                <td colSpan={columns.length-1}>
                                    <DataListPages
                                        currentPage={currentPage}
                                        totalPages={totalPages}
                                        onUpdatePage={onUpdatePage}
                                    />
                                </td>
                                <td className="text-right">
                                    <select
                                        className='form-control'
                                        id="recordsPerPage"
                                        name="recordsPerPage"
                                        value={recordsPerPage}
                                        defaultValue={recordsPerPage}
                                        onChange={(e) => onChangeRecordsPerPage(e)}
                                        style={{maxWidth: '120px', display: 'inline'}}
                                    >
                                        <option value="">-- SELECT --</option>
                                        <option value="5">5</option>
                                        <option value="25">25</option>
                                        <option value="50">50</option>
                                        <option value="100">100</option>
                                        <option value="10000">all</option>
                                    </select>
                                </td>
                            </tr>
                        </thead>
                        <thead className="table-dark">
                            <tr>
                                {columns && <Fragment>
                                    { columns.map((column, index) => {
                                        if (column.dataField === 'active' || column.dataField === 'status') hasStatus = true;
                                        if (column.type === "bool") {
                                            if (column.sort ===  true) {
                                                return (<th key={index} className="text-center cursor-pointer">{languageText[languageCode].components[tableName].columns[column.dataField]}</th>);
                                            } else {
                                                return (<th key={index} className="text-center">{languageText[languageCode].components[tableName].columns[column.dataField]}</th>);
                                            }
                                        } else {
                                            if (column.sort ===  true) {
                                                return (<th key={index} className="cursor-pointer" onClick={() => changeSort(column.dataField, sortDirection) }>
                                                    {sortFieldName === column.dataField && typeof(sortDirection) !== 'undefined' && sortDirection === 'ASC' && <SortDown size={12} />}
                                                    {sortFieldName === column.dataField && typeof(sortDirection) !== 'undefined' && sortDirection === 'DESC' && <SortUp size={12} />}
                                                    {languageText[languageCode].components[tableName].columns[column.dataField]}
                                                </th>);
                                            } else {
                                                if (column.dataField === 'tools') {
                                                    return (<th key={index} className="text-right">{languageText[languageCode].components[tableName].columns[column.dataField]}</th>);
                                                } else {
                                                    return (<th key={index}>{languageText[languageCode].components[tableName].columns[column.dataField]}</th>);
                                                }
                                            }
                                        }
                                    })}
                                </Fragment>}
                            </tr>
                        </thead>
                        <tbody>
                        {tableData && <Fragment>
                            {tableData.length !== 0 && <Fragment>
                                {tableData.map((dataRow, index) => {
                                    return (<tr key={index}>
                                        {columns.map((column, index) => {
                                            let value = dataRow[column.dataField];
                                            if (column.dataField === "tools")
                                            {
                                                return (<td key={index} nowrap="true" style={{width: 220}} className="text-right">
                                                    {settings.showView === true && <Fragment><Button size="sm" color="primary" onClick={() => onView(dataRow.id)}><ArchiveIcon size={18} /></Button>{' '}</Fragment>}
                                                    {tableName === "organizations" && <Fragment><Button size="sm" color="success" onClick={() => onViewsUsers(dataRow.id)}><UsersIcons size={18} /></Button>{' '}</Fragment>}
                                                    {tableName === "users" && dataRow.status === 2 && <Fragment><Button size="sm" color="success" onClick={() => onViewsUsers(dataRow.id)}><EmailIcon size={18} /></Button>{' '}</Fragment>}
                                                    {(dataRow.editButton === "true" || dataRow.editButton === true) && settings.showEdit !== false && <Fragment>
                                                        {tableName !== "mentee" && <Button size="sm" color="primary" onClick={() => onEdit(dataRow.id)}><EditIcon size={18} /></Button>}
                                                        {tableName === "mentee" && <Link to={`/mentee/manage/${dataRow.id}`} className="btn btn-sm btn-primary" onClick={() => onEdit(dataRow.id)}><EditIcon size={18} /></Link>}
                                                    </Fragment>}{' '}
                                                    {(dataRow.deleteButton === "true" || dataRow.deleteButton === true) && settings.showDelete !== false && <Button size="sm" color="danger" onClick={() => onDelete(dataRow.id)}><DeleteIcon size={18} /></Button>}{' '}
                                                    {hasStatus === true && settings.showArchive !== false && <Button size="sm" color="warning" onClick={() => onArchive(dataRow.id)}><ArchiveIcon size={18} /></Button>}
                                                </td>);
                                            }
                                            else if (column.type === "bool")
                                            {
                                                return (<td key={index} style={{width: 75}} className="text-center">
                                                    {(value === 1 || value === '1' || value === true || value === "true") && <ActiveIcon size={18}/>}
                                                    {(value === 0 || value === '0' || value === false || value === "false") && <InActiveIcon size={18}/>}
                                                </td>);
                                            }
                                            else if (column.type === "image")
                                            {
                                                if (value !== '') {
                                                    return (<td key={index}>
                                                        {value && <img src={value} style={{maxWidth: 60}} alt={value} border="0" />}
                                                    </td>);
                                                } else {
                                                    return (<td key={index}>&nbsp;</td>);
                                                }
                                            }
                                            else
                                            {
                                                return (<td key={index} dangerouslySetInnerHTML={{__html: value}} />);
                                            }
                                        })}
                                    </tr>);
                                })}
                            </Fragment>}
                            {tableData.length === 0 && <Fragment>
                                <tr>
                                    <td colSpan={columns.length} className="text-center">
                                        {languageText[languageCode].buttons["noRecords"]}
                                    </td>
                                </tr>
                            </Fragment>}
                        </Fragment>}
                        </tbody>
                        <tfoot>
                        <tr><td colSpan={columns.length} /></tr>
                        <tr>
                            <td colSpan={columns.length-1} className="pt-4">
                                <DataListPages
                                    currentPage={currentPage}
                                    totalPages={totalPages}
                                    onUpdatePage={onUpdatePage}
                                />
                            </td>
                            <td className="text-right pt-4">
                                <select
                                    className='form-control'
                                    id="recordsPerPage"
                                    name="recordsPerPage"
                                    value={recordsPerPage}
                                    defaultValue={recordsPerPage}
                                    onChange={(e) => onChangeRecordsPerPage(e)}
                                    style={{maxWidth: '120px', display: 'inline'}}
                                >
                                    <option value="">-- SELECT --</option>
                                    <option value="5">5</option>
                                    <option value="25">25</option>
                                    <option value="50">50</option>
                                    <option value="100">100</option>
                                    <option value="10000">all</option>
                                </select>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={columns.length}>
                                Total Pages : {totalPages} -
                                Total Records : {totalRecords}
                            </td>
                        </tr>
                        </tfoot>
                    </Table>
                </div>
            </Card>);
        }
    }
}
class DataListPages extends Component {
    render() {
        const { currentPage, totalPages, onUpdatePage } = this.props;
        const pages = [];

        // GO BACK 1 PAGE
        if (parseInt(currentPage) === 1) {
            pages.push(<li className="page-item disabled"><span className="page-link">&laquo;</span></li>);
        } else {
            pages.push(<li className="page-item"><button className="page-link" onClick={() => onUpdatePage(1)}>First</button></li>);
            pages.push(<li className="page-item"><button className="page-link" onClick={() => onUpdatePage(currentPage-1)}>&laquo;</button></li>);
        }

        if (parseInt(totalPages) > 15) {
            let startPage = parseInt(currentPage) - 6;
            let endPage = parseInt(currentPage) + 6;
            if (startPage < 1) startPage=1;
            if (endPage > totalPages) endPage=totalPages;
            if (endPage < 15 && totalPages > 20) endPage = 15;
            for (let i=startPage; i <= endPage; i++) {
                if (i === parseInt(currentPage)) {
                    pages.push(<li className="page-item active"><span className="page-link">{i}<span className="sr-only">(current)</span></span></li>);
                } else {
                    pages.push(<li className="page-item"><button className="page-link" onClick={() => onUpdatePage(i)}>{i}</button></li>);
                }
            }
        } else {
            for (let i=1; i <= parseInt(totalPages); i++) {
                if (i === parseInt(currentPage)) {
                    pages.push(<li className="page-item active"><span className="page-link">{i}<span className="sr-only">(current)</span></span></li>);
                } else {
                    pages.push(<li className="page-item"><button className="page-link" onClick={() => onUpdatePage(i)}>{i}</button></li>);
                }
            }
        }

        // GO FORWARD ONE PAGE
        if (parseInt(totalPages) === parseInt(currentPage)) {
            pages.push(<li className="page-item disabled"><span className="page-link">&raquo;</span></li>);
        } else {
            pages.push(<li className="page-item"><button className="page-link" onClick={() => onUpdatePage(currentPage+1)}>&raquo;</button></li>);
            pages.push(<li className="page-item"><button className="page-link" onClick={() => onUpdatePage(totalPages)}>Last</button></li>);
        }

        return(<nav aria-label="Page List">
            <ul className="pagination">
                {pages}
            </ul>
        </nav>);
    }
}


const mapStateToProps = state => ({
    auth: state.auth.user,
    data: state.pages.data,
});

export default connect(mapStateToProps)(DataList);