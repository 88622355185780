import React, {Component} from "react";
import {languageText} from "../config";
import DataOverview from "../chcomponents/DataOverview";

const tableName = "Orders";

class Orders extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pageMode: "loading",
            tableColumns : [
                {
                    dataField: "active",
                    type: "bool",
                    sort: false,
                },
                {
                    dataField: "orderNumber",
                    sort: false
                },
                {
                    dataField: "onfStatus",
                    sort: false
                },
                {
                    dataField: "imageURL",
                    type: "image",
                    sort: false
                },
                {
                    dataField: "dateCreated",
                    sort: true
                },
                {
                    dataField: "deliveryDate",
                    sort: true
                },
                {
                    dataField: "billingName",
                    sort: true
                },
                {
                    dataField: "shippingName",
                    sort: true
                },
                {
                    dataField: "orderTotal",
                    sort: true
                },
                {
                    dataField: "tools",
                    sort: false,
                }
            ],
            fieldData: [
                {
                    fieldName: "orderNumber",
                    fieldType: "number",
                    colSize: 3
                },
                {
                    fieldName: "subOrderNumber",
                    fieldType: "number",
                    colSize: 3
                },
                {
                    fieldName: "onfStatus",
                    fieldType: "number",
                    colSize: 3
                },
                {
                    fieldName: "financialStatus",
                    fieldType: "number",
                    colSize: 3
                },
                {
                    fieldName: "fulfillmentStatus",
                    fieldType: "number",
                    colSize: 3
                },
                {
                    fieldName: "dateCreated",
                    fieldType: "number",
                    colSize: 3
                },
                {
                    fieldName: "deliveryDate",
                    fieldType: "number",
                    colSize: 3
                },
                {
                    fieldName: "orderTotal",
                    fieldType: "text",
                    colSize: 3
                },
                {
                    fieldName: "orderSubTotal",
                    fieldType: "text",
                    colSize: 3
                },
                {
                    fieldName: "orderTax",
                    fieldType: "text",
                    colSize: 3
                },
                {
                    fieldName: "userEmail",
                    fieldType: "text",
                    colSize: 3
                },
                {
                    fieldName: "billingName",
                    fieldType: "text",
                    colSize: 3
                },
                {
                    fieldName: "billingAddress1",
                    fieldType: "text",
                    colSize: 3
                },
                {
                    fieldName: "billingAddress2",
                    fieldType: "text",
                    colSize: 3
                },
                {
                    fieldName: "billingCity",
                    fieldType: "text",
                    colSize: 3
                },
                {
                    fieldName: "billingState",
                    fieldType: "text",
                    colSize: 3
                },
                {
                    fieldName: "billingZip",
                    fieldType: "text",
                    colSize: 3
                },
                {
                    fieldName: "shippingName",
                    fieldType: "text",
                    colSize: 3
                },
                {
                    fieldName: "shippingAddress1",
                    fieldType: "text",
                    colSize: 3
                },
                {
                    fieldName: "shippingAddress2",
                    fieldType: "text",
                    colSize: 3
                },
                {
                    fieldName: "shippingCity",
                    fieldType: "text",
                    colSize: 3
                },
                {
                    fieldName: "shippingState",
                    fieldType: "text",
                    colSize: 3
                },
                {
                    fieldName: "shippingZip",
                    fieldType: "text",
                    colSize: 3
                }
            ],
            fieldDataDefault: {},
        }
    }
    render() {
        const { tableColumns, fieldData, fieldDataDefault } = this.state;
        const { languageCode } = this.props.auth.user.user;
        return (<DataOverview
            singleEdit={false}
            tableName={tableName}
            languageTable={tableName}
            sortFieldName="deliveryDate"
            sortDirection="DESC"
            settings={{"showView": true, "showEdit": false, "showClone": false, "showAdd": false, "showExport": false, "showDelete": false, "showArchive": false}}
            pageTitle={languageText[languageCode].components[tableName].pageTitle}
            listTitle={languageText[languageCode].components[tableName].listTitle}
            listSubTitle={languageText[languageCode].components[tableName].listSubTitle}
            addTitle={languageText[languageCode].components[tableName].addTitle}
            addSubTitle={languageText[languageCode].components[tableName].addSubTitle}
            editTitle={languageText[languageCode].components[tableName].editTitle}
            editSubTitle={languageText[languageCode].components[tableName].editSubTitle}
            tableColumns={tableColumns}
            fieldData={fieldData}
            fieldDataDefault={fieldDataDefault}
        />);
    }
}
export default Orders;
