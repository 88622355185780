import {
    GET_CHAT_MESSAGES,
    GET_DATA,

    GET_CONFIG,
    SAVE_CONFIG,

    SAVE_EMAIL_ADDRESS,
    SAVE_EMAIL_ADDRESS_ERROR, LOGIN_FAIL, USER_LOADED
} from './types';
import axios from 'axios';

import { ApiURL } from "../../config";
import {returnErrors} from "./messages";

export const getTable = (tableName, currentPage, recordsPerPage, sortFieldName, sortDirection, searchText) => async (dispatch, getState) => {
    try {
        let res = await axios.post(`${ApiURL}/getTable`, {tableName, currentPage, recordsPerPage, sortFieldName, sortDirection, searchText}, tokenConfig(getState) );
        dispatch ({
            type: GET_DATA,
            payload: res.data
        });
    }
    catch (err) {
        console.log("logAction: ", err);
    }
};
export const getContent = (contentPage, languageCode) => async (dispatch, getState) => {
    try {
        let res = await axios.post(`${ApiURL}/getContent`, {contentPage, languageCode}, tokenConfig(getState) );
        dispatch ({
            type: GET_DATA,
            payload: res.data
        });
    }
    catch (err) {
        console.log("logAction: ", err);
    }
};
export const getRecord = (tableName, tableId) => async (dispatch, getState) => {
    try {
        let res = await axios.post(`${ApiURL}/getRecord`, {tableName, tableId}, tokenConfig(getState) );
        dispatch ({
            type: GET_DATA,
            payload: res.data
        });
    }
    catch (err) {
        console.log("logAction: ", err);
    }
};
export const viewRecord = (tableName, tableId) => async (dispatch, getState) => {
    try {
        let res = await axios.post(`${ApiURL}/viewRecord`, {tableName, tableId}, tokenConfig(getState) );
        dispatch ({
            type: GET_DATA,
            payload: res.data
        });
    }
    catch (err) {
        console.log("logAction: ", err);
    }
};
export const deleteRecord = (tableName, tableId) => async (dispatch, getState) => {
    try {
        let res = await axios.post(`${ApiURL}/deleteRecord`, {tableName, tableId}, tokenConfig(getState) );
        dispatch ({
            type: GET_DATA,
            payload: res.data
        });
    }
    catch (err) {
        console.log("logAction: ", err);
    }
};
export const archiveRecord = (tableName, tableId, activeStatus) => async (dispatch, getState) => {
    try {
        let res = await axios.post(`${ApiURL}/archiveRecord`, {tableName, tableId, activeStatus}, tokenConfig(getState) );
        dispatch ({
            type: GET_DATA,
            payload: res.data
        });
    }
    catch (err) {
        console.log("logAction: ", err);
    }
};
export const saveRecord = (tableName, tableId, data) => async (dispatch, getState) => {
    try {
        let res = await axios.post(`${ApiURL}/saveRecord`, {tableName, tableId, data}, tokenConfig(getState) );
        dispatch ({
            type: GET_DATA,
            payload: res.data
        });
    }
    catch (err) {
        console.log("logAction: ", err);
    }
};
export const saveOrder = (orderNumber, subOrderNumber, data) => async (dispatch, getState) => {
    try {
        let res = await axios.post(`${ApiURL}/saveOrder`, {orderNumber, subOrderNumber, data}, tokenConfig(getState) );
        dispatch ({
            type: GET_DATA,
            payload: res.data
        });
    }
    catch (err) {
        console.log("logAction: ", err);
    }
};
export const estimateShipping = (orderId, subOrderId) => async (dispatch, getState) => {
    try {
        let res = await axios.post(`${ApiURL}/estimateShipping`, {orderId, subOrderId}, tokenConfig(getState) );
        dispatch ({
            type: GET_DATA,
            payload: res.data
        });
    }
    catch (err) {
        console.log("logAction: ", err);
    }
};

export const getProfileInfo = () => async (dispatch, getState) => {
    try {
        let res = await axios.get(`${ApiURL}/getProfileInfo`, tokenConfig(getState) );
        dispatch ({
            type: GET_DATA,
            payload: res.data
        });
    }
    catch (err) {
        console.log("logAction: ", err);
    }
};

export const getChatMessages = (roomId) => async (dispatch, getState) => {
    try {
        let res = await axios.get(`${ApiURL}/getChatMessages/${roomId}`, tokenConfig(getState) );
        dispatch ({
            type: GET_CHAT_MESSAGES,
            payload: res.data
        });
    }
    catch (err) {
        console.log("logAction: ", err);
    }
};
export const getChatRooms = () => async (dispatch, getState) => {
    try {
        let res = await axios.get(`${ApiURL}/getChatRooms`, tokenConfig(getState) );
        dispatch ({
            type: GET_CHAT_MESSAGES,
            payload: res.data
        });
    }
    catch (err) {
        console.log("logAction: ", err);
    }
};

export const getNotifications = () => async (dispatch, getState) => {
    try {
        let res = await axios.get(`${ApiURL}/getNotifications`, tokenConfig(getState) );
        dispatch ({
            type: USER_LOADED,
            payload: res.data
        });
    }
    catch (err) {
        console.log("logAction: ", err);
    }
};




export const getNotificationList = () => async (dispatch, getState) => {
    try {
        let res = await axios.get(`${ApiURL}/getNotificationList`, tokenConfig(getState) );
        dispatch ({
            type: GET_DATA,
            payload: res.data
        });
    }
    catch (err) {
        console.log("logAction: ", err);
    }
};
export const markNotificationRead = (notificationId) => async (dispatch, getState) => {
    try {
        let res = await axios.post(`${ApiURL}/markNotificationRead`, {notificationId}, tokenConfig(getState) );
        dispatch ({
            type: GET_DATA,
            payload: res.data
        });
    }
    catch (err) {
        console.log("logAction: ", err);
    }
};










export const saveConfigSettings = (fromName, fromEmail, paymentSubject, reportEmailTime, reportEmailSubject, reportEmailMessage) => async (dispatch, getState) => {
    try {
        const res = await axios.post(`${ApiURL}/saveConfigSettings`, { fromName, fromEmail, paymentSubject, reportEmailTime, reportEmailSubject, reportEmailMessage }, tokenConfig(getState));
        dispatch ({
            type: SAVE_CONFIG,
            payload: res.data
        });
    }
    catch (err) {
        dispatch(returnErrors(err.response.data, err.response.status));
        dispatch({type: LOGIN_FAIL});
    }
};
export const getConfigSettings = () => async (dispatch, getState) => {
    try {
        const res = await axios.get(`${ApiURL}/getConfigSettings`, tokenConfig(getState));
        dispatch ({
            type: GET_CONFIG,
            payload: res.data
        });
    }
    catch (err) {
        dispatch(returnErrors(err.response.data, err.response.status));
        dispatch({type: LOGIN_FAIL});
    }
};



export const saveEmailAddresses = (emailAddresses, reportType) => async (dispatch, getState) => {
    try {
        const res = await axios.post(`${ApiURL}/saveEmailAddresses`, { emailAddresses, reportType }, tokenConfig(getState));
        dispatch ({ type: SAVE_EMAIL_ADDRESS, payload: res.data });
    }
    catch (err) {
        dispatch({ type: SAVE_EMAIL_ADDRESS_ERROR, payload: err.response });
        dispatch({type: LOGIN_FAIL});
    }
};

export const tokenConfig = (getState) => {
    // GET TOKEN FROM STATE
    const token = getState().auth.token;

    // HEADERS
    const config = {
        withCredentials: true,
        headers: {
            "Content-Type":"application/json"
        }
    };

    // IF TOKEN ADD TO HEADERS
    if (token) {
        config.headers['Authorization']= token;
    }
    return config;
};