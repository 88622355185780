import React from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Loading from "../../components/Loader";

const PrivateRoute = ({ component: Component, layout: Layout, auth, ...rest}) => {
    return (<Route
        {...rest}
        render={props => {
            if (typeof(Component) !== 'undefined' ) {
                if (auth.isLoading || auth.isAuthenticated === null) {
                    return <Loading />;
                } else if (auth.isAuthenticated === false) {
                    return <Redirect to={`/auth/sign-in`} />;
                } else {
                    return (<Layout>
                        <Component {...props} auth={auth}  />
                    </Layout>);
                }
            }
        }}
    />);
}

PrivateRoute.propTypes = {
    auth: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
    auth: state.auth
});

export default connect(mapStateToProps)(PrivateRoute);