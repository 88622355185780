import React from 'react';
import classnames from "classnames";
import ShowInputError from "./ShowInputError";

const FormInputRadio = ({name, value, label, placeholder, type, helpText, onChange, error, valueOptions, defaultValue}) => {
    return (
        <div className="form-group row border-bottom pb-2">
            <label htmlFor={name} className="col-sm-4">{label}</label>
            <div className="col-sm-8">
                {valueOptions ? (valueOptions.map((options, index) => {
                    let defaultChecked = null;
                    if (parseInt(value) === parseInt(options.value)) defaultChecked = 'checked';
                    else if (value === options.value) defaultChecked = 'checked';
                    else if (defaultValue === options.value && value === '') defaultChecked = 'checked';

                    return (<div key={index} className="form-check form-check-inline">
                        <input
                            className={classnames('form-check-input', {'is-invalid': error})}
                            type="radio"
                            defaultChecked={defaultChecked}
                            name={name}
                            id={name + index}
                            value={options.value}
                            onChange={onChange}
                        />
                        <label className="form-check-label" htmlFor={name + index}>
                            {options.name}
                        </label>
                    </div>);
                })) : null
                }
            </div>
            {helpText && <small id={name+"Help"} className="col-12 form-text text-muted">{helpText}</small>}
            {error && <ShowInputError error={error} />}
        </div>
    )
};

export default FormInputRadio